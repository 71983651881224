import React, { useEffect, useState } from 'react';
import styles from './UserAssessmentsResults.module.scss';

import classNames from 'classnames';

import * as api from 'api';

import { useDispatch, useSelector } from 'react-redux';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import { getAssessment, listAssessments } from 'store/actions';

import { useTranslate } from 'utils/translator';
import { getMappedNineLevelsResults } from 'utils/assessment';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { scrollIntoView } from 'utils/scrolling';

import {
  Link,
  Profile,
} from 'ui/basic';

import {
  Big5Result,
  CompetenciesResult,
  NineLevelsResult,
  PotentialResult,
  RmpResult,
  WorkPreferencesResult,
  SkillsResult,
  CliftonResult,
  Peer360Result,
  IstResult,
} from 'ui/molecules';
import AttachmentsCard from 'features/+candidates/pages/CandidateSummary/AttachmentsCard';
import { selectCurrentLanguage } from 'store/selectors/localisation';


const ASSESSMENT_RESULT_TITLES = {
  [ASSESSMENT_TYPES.BIG5]: 'big5_results_title',
  [ASSESSMENT_TYPES.POTENTIAL]: 'potential_results_title',
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 'workpreferences_results_title',
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 'keycomp_results_title',
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 'leadershipcomp_results_title',
  [ASSESSMENT_TYPES.RMP]: 'rmp_results_title',
  [ASSESSMENT_TYPES.NINE_LEVELS]: '9levels_results_title',
};


const UserAssessmentsResults = (props) => {
  const {
    user, token, isCandidate, results,
  } = props;

  const dispatch = useDispatch();
  const translate = useTranslate();

  const [ userImage, setUserImage ] = useState('');
  useEffect(() => {
    if (userImage || !token) {
      return;
    }

    api.get_base64('/core/user/picture', {}, { Authorization: `Bearer ${token}` })
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setUserImage(`data:image/png;base64, ${data}`);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ token, userImage ]);

  // ASSESSMENTS
  const currentLanguage = useSelector(selectCurrentLanguage);
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);
  useEffect(() => {
    dispatch(listAssessments());
  }, [ dispatch, currentLanguage ]);

  // ATTACHMENTS
  const [ attachments, setAttachments ] = useState([]);
  useEffect(() => {
    api.get('/core/user/attachments', { user: user?.id })
    .then(({ ok, data = {} }) => {
      if (ok) {
        setAttachments(data.attachments);
      } else {
        console.error(data.error?.errorMessage);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ user ]);

  useEffect(() => {
    assessmentsFromStore.forEach((assessment) => {
      // if assessment is a custom assessment
      if (!Object.values(ASSESSMENT_TYPES).includes(assessment.id)) {
        dispatch(getAssessment(assessment.id));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch, currentLanguage, assessmentsFromStore.length ]);

  const [ reorderedResults, setReorderedResults ] = useState([]);
  useEffect(() => {
    if (!results?.length) {
      return;
    }

    const res = [];
    assessmentsFromStore.forEach((assessment) => {
      const foundItem = results.find((resultItem) => resultItem.assessment === assessment.id);
      if (foundItem) {
        res.push({ ...foundItem, title: assessment.title });
      }
    });
    setReorderedResults(res);
  }, [ assessmentsFromStore, results ]);

  const renderResultComponent = (resultItem) => {
    const assessmentType = resultItem.assessment;
    const thisAssessment = assessmentsFromStore.find((a) => a.id === assessmentType);

    switch (assessmentType) {
      case ASSESSMENT_TYPES.BIG5:
        return <Big5Result results={resultItem.result.results} requestFeedback={false} />;
      case ASSESSMENT_TYPES.POTENTIAL:
        return <PotentialResult showCallout results={resultItem.result.results} requestFeedback={false} />;
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return <WorkPreferencesResult results={resultItem.result.results} />;
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        return (
          <CompetenciesResult
            assessmentType={assessmentType}
            results={resultItem.result.results}
          />
        );
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return (
          <CompetenciesResult assessmentType={assessmentType} results={resultItem.result.results} />
        );
      case ASSESSMENT_TYPES.RMP:
        return (
          <RmpResult results={resultItem.result.results} requestFeedback={false} />
        );
      case ASSESSMENT_TYPES.NINE_LEVELS: {
        const [ mappedResults ] = getMappedNineLevelsResults(resultItem.result.results);
        return (
          <NineLevelsResult
            results={mappedResults}
            signatureValues={resultItem.result.results.filter((rItem) => rItem.id.includes('highvalues'))}
            requestFeedback={false}
          />
        );
      }
      case ASSESSMENT_TYPES.IST:
        return (
          <IstResult
            ist={resultItem.result}
            user={user}
            coachingView
          />
        );
      default:
        if (thisAssessment?.render_results === 'clifton') {
          return <CliftonResult strengths={resultItem.result.results} />;
        }

        if (thisAssessment?.peerAssessment) {
          return (
            <Peer360Result
              userId={user?.id}
              assessmentData={assessmentsFromStore.find((a) => a.id === assessmentType)}
              assessmentResults={resultItem.result.results}
            />
          );
        }

        return (
          <SkillsResult
            userId={user?.id}
            assessmentType={assessmentType}
            results={resultItem.result.results}
            reportContent={resultItem.result.results}
          />
        );
    }
  };

  return (
    <div className={styles.userAssessmentsResults}>
      <div className={styles.gridContainer}>
        <div className={styles.personalInfo}>
          <div className={classNames(styles.subBlock)}>

            { /* if user's data is not anonymized */ }
            { isCandidate && (
              <div className={styles.profile}>
                <Profile
                  user={user}
                  userImage={userImage}
                  showCompanyData={false}
                />
              </div>
            ) }

            { /* ATTACHMENTS */ }
            { attachments.length > 0 && (
              <div className={styles.attachments}>
                <AttachmentsCard
                  attachments={attachments}
                  getDownloadParams={(file) => ([ `/core/user/attachments/${file.id}/file` ])}
                  readOnly
                />
              </div>
            ) }

            { (reorderedResults.length > 0) && (
              <div className={styles.anchors}>
                <div className={styles.title}>
                  { translate('employee_assessements_quick_access') }
                </div>
                { reorderedResults.map((resultItem) => (
                  <Link
                    key={resultItem.assessment}
                    type='anchor'
                    onClick={() => scrollIntoView(resultItem.assessment)}
                  >
                    { translate(ASSESSMENT_RESULT_TITLES[resultItem.assessment]) || resultItem.title }
                  </Link>
                )) }
              </div>
            ) }

          </div>
        </div>

        <div className={styles.assessmentsInfo}>
          { (reorderedResults.length > 0) && (
            <>
              <div className={styles.title}>
                { translate('employee_strengths') }
              </div>

              { /* ASSESSMENTS RESULTS */ }
              <div>
                { reorderedResults.map((resultItem) => {
                  if (!resultItem || !resultItem.result) {
                    return null;
                  }

                  return (
                    <div
                      id={resultItem.assessment}
                      key={resultItem.assessment}
                      className={styles.assessment}
                    >
                      { renderResultComponent(resultItem) }
                    </div>
                  );
                }) }
              </div>
            </>
          ) }

          { (results && results.length === 0) && (
            <div className={styles.noAssessments}>
              <div className={styles.title}>
                { translate('employee_no_assessments', [ '{{username}}', user && user.name ]) }
              </div>
              <div className={styles.description}>
                { translate('employee_no_assessments_descr') }
              </div>
            </div>
          ) }

        </div>

      </div>

    </div>
  );
};

export default UserAssessmentsResults;
